import React, { ReactNode, useEffect } from 'react';
import { isEfluxDomain } from 'utils/domains';
import { request } from 'utils/api';
import { loadScript } from 'utils/script';
import { Button, Message } from 'semantic';
import PageCenter from 'components/PageCenter';
import { JWT_KEY, SANDBOX_JWT_KEY, getToken } from 'utils/api';
import { parseToken } from 'utils/token';
import {
  canAccess,
  hasGlobalRoles,
  isSuperAdmin as legacyIsSuperAdminFunc,
} from 'utils/roles';

import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import {
  inSandboxMode,
  SANDBOX_ERROR_TITLE,
  SANDBOX_ERROR_MESSAGE,
} from 'utils/sandbox';
import { leaveSandbox } from 'utils/api';
import { usePlatformModules } from 'contexts/platformModules';
import { HUBSPOT_PORTAL_ID } from 'utils/env';
import { User } from 'types/user';
import { Provider } from 'types/provider';
import { FacilitatorPermissions } from 'types/facilitator-permissions';
import { identifyUser } from 'utils/hubspot';

const UserContext = React.createContext<UserContextType>({} as UserContextType);

function getJWTToken() {
  if (getToken()) {
    return getToken();
  }

  const oldToken =
    window.sessionStorage.getItem('jwt') || window.localStorage.getItem('jwt');

  if (oldToken) {
    window.localStorage.setItem(JWT_KEY, oldToken);
    window.localStorage.removeItem('jwt');
    return oldToken;
  }
  return null;
}

function loadAnalyticsScripts(provider: any) {
  // we enable web analytics if the current provider has it enabled,
  // or in the event of an enterprise account, if the parent provider has it enabled
  if (
    !provider.enableWebAnalytics &&
    !provider?.parentProvider?.enableWebAnalytics
  ) {
    return;
  }

  loadScript('https://widget.intercom.io/widget/zc515shr');
  loadScript('https://connect.facebook.net/en_US/fbevents.js');
  loadScript('https://www.googletagmanager.com/gtm.js?id=GTM-MM359TS');
  // dont track anything unless e-flux and dont track if you are login as a user
  if (provider.slug === 'e-flux' && !window.sessionStorage?.e_flux_jwt) {
    loadScript(`//js-eu1.hs-scripts.com/${HUBSPOT_PORTAL_ID}.js`);
  }
}

export type UserContextType = {
  providerId: string; // ⚠️ Note: this is actually the provider slug when the provider is e-flux, and id otherwise
  setProviderId: (providerId: string) => void;
  fetchProvider: (providerId: string) => Promise<void>;
  provider: Provider | undefined;
  setProvider: (provider: Provider) => void;
  canAccess: (resource: string, action: string) => boolean;
  isSuperAdmin: () => boolean;
  hasPlatformFeature: (feature: string) => boolean;
  hasPlatformFeatureInModule: (module: string) => boolean;
  fetchUser: (options?: any) => Promise<void>;
  setUser: (user: User) => void;
  setToken: (token: string) => void;
  token: string | null;
  exchangeRates: any[];
  tokenPayload: any;
  user: User | undefined;
  logout: () => void;
};

type UserProviderProps = {
  children: ReactNode;
};

const UserProvider = ({ children }: UserProviderProps) => {
  const { load: loadModules } = usePlatformModules();
  const [provider, setProvider] = React.useState<Provider>();
  const [exchangeRates, setExchangeRates] = React.useState<any>([]);
  const [error, setError] = React.useState<{
    title: string;
    message: string;
  }>();
  const [user, setUser] = React.useState<User>();

  const [token, setToken] = React.useState<string | null>(getJWTToken());
  const { t } = useTranslation();

  //XXX stupid logic for docs they want the road provider theming on docs if e-flux provider is selected
  function getDefaultProvider() {
    const providerId = !isEfluxDomain()
      ? window.localStorage.getItem('setProviderId') ||
        document.location.hostname
      : window.localStorage.getItem('setProviderId') || 'e-flux';

    if (providerId == 'e-flux' && window.location.pathname.includes('/docs')) {
      return 'road';
    }
    return providerId;
  }

  const [providerId, setProviderId] = React.useState(getDefaultProvider());

  //XXX stupid logic for docs they want the road provider theming on docs if e-flux provider is selected
  useEffect(() => {
    const pathname = window.location.pathname;
    if (providerId === 'e-flux' && pathname.includes('/docs')) {
      setProviderId('road');
    } else if (providerId === 'road' && !pathname.includes('/docs')) {
      setProviderId('e-flux');
    }
  }, [window.location.pathname, providerId]);

  function logout() {
    window.localStorage.removeItem(JWT_KEY);
    window.sessionStorage.removeItem(JWT_KEY);
    // legacy paths can be removed in a few months
    window.localStorage.removeItem('_jwt');
    window.sessionStorage.removeItem('_jwt');
    window.sessionStorage.removeItem(SANDBOX_JWT_KEY);
    window.localStorage.removeItem(SANDBOX_JWT_KEY);
    window.localStorage.removeItem('setProviderId');
    window.localStorage.removeItem('signupBillingPlanId');
    Sentry.setUser(null);
  }

  function switchEnvironment() {
    leaveSandbox();
    window.location.reload();
  }

  function fetchProvider(providerId: string) {
    return request({
      method: 'GET',
      path: `/1/providers/public/${providerId}`,
    })
      .then(({ data }) => {
        loadAnalyticsScripts(data);
        setProvider(data);
        return data;
      })
      .catch((e) => {
        if (e.status === 404) {
          setError({
            title: 'Provider not configured',
            message:
              "Please contact support the provider hasn't been configured",
          });
        } else if (
          e instanceof TypeError &&
          e.message === 'Failed to fetch' &&
          inSandboxMode
        ) {
          setError({
            title: t('sandboxError.title', SANDBOX_ERROR_TITLE),
            message: t('sandboxError.message', SANDBOX_ERROR_MESSAGE),
          });
          return;
        } else {
          setError(e);
        }
      });
  }

  async function fetchUser(options = {}) {
    const { data } = await request({
      ...options,
      method: 'GET',
      path: `/1/users/me`,
    });
    //XXX this is bad ... but old code relies on it
    // @ts-ignore
    window.user = data;
    Sentry.setUser({ id: data.id });

    identifyUser(data);

    const canListProviders =
      (provider &&
        canAccess(data, provider.id, data.accountId, 'providers', 'read')) ||
      hasGlobalRoles(data) ||
      data?.providerRoles?.length > 1;

    if (!canListProviders && provider?.id !== data.providerId) {
      const userProvider = await fetchProvider(data.providerId);

      if (userProvider.customDomain) {
        setError({
          title: t('wrongProvider.redirectTitle', `Redirecting ...`),
          message: t(
            'wrongProvider.redirectBody',
            'You are being redirected to {{userProviderName}} to access your account.',
            { userProviderName: userProvider.name }
          ),
        });
        setTimeout(() => {
          window.location.href = `https://${userProvider.customDomain}`;
        }, 3000);
        return;
      }
    }
    setUser(data);
  }

  React.useEffect(() => {
    if (token) {
      if (window.sessionStorage.getItem(JWT_KEY) !== token) {
        window.localStorage.setItem(JWT_KEY, token);
      }
    } else {
      window.localStorage.removeItem(JWT_KEY);
      window.sessionStorage.removeItem(JWT_KEY);
      window.localStorage.removeItem('setProviderId');
      window.localStorage.removeItem('signupBillingPlanId');
    }
  }, [token]);

  React.useEffect(() => {
    window.localStorage.setItem('setProviderId', providerId);
    fetchProvider(providerId);
  }, [providerId]);

  React.useEffect(() => {
    // @ts-ignore
    window.provider = provider;
    if (provider) {
      loadModules();
    }
    Sentry.setUser({ segment: provider?.slug });
  }, [provider]);

  function hasPlatformFeature(feature: string) {
    return !!user?.platformFeatures?.find((f) => f?.key === feature);
  }

  function isSuperAdmin(): boolean {
    if (!user) {
      return false;
    }
    return legacyIsSuperAdminFunc(user);
  }

  function hasPlatformFeatureInModule(module: string) {
    return !!user?.platformFeatures?.find(
      (f) => f?.key?.indexOf(`${module}:`) === 0
    );
  }

  function wrappedCanAccess(resource: any, action: any) {
    if (!user) {
      return false;
    }
    if (!provider) {
      return false;
    }
    return canAccess(user, provider.id, user.accountId!, resource, action);
  }

  const tokenPayload = token ? parseToken(token) : {};

  const values = React.useMemo<UserContextType>(() => {
    return {
      providerId, // ⚠️ Note: this is actually the provider slug when the provider is e-flux, and id otherwise
      setProviderId,
      fetchProvider,
      provider,
      setProvider,
      canAccess: wrappedCanAccess,
      isSuperAdmin,
      hasPlatformFeature,
      hasPlatformFeatureInModule,
      fetchUser,
      setUser,
      setToken,
      token,
      exchangeRates,
      tokenPayload,
      user,
      logout,
    };
  }, [
    provider,
    exchangeRates,
    fetchProvider,
    setProvider,
    setUser,
    fetchUser,
    wrappedCanAccess,
    tokenPayload,
    user,
    logout,
    setToken,
    token,
  ]);

  if (error) {
    return (
      <PageCenter>
        <Message error header={error.title} content={error.message} />
        {error.title === SANDBOX_ERROR_TITLE && (
          // @ts-ignore
          <Button onClick={switchEnvironment}>
            {t('buttons.switchEnvironment', 'Back to Standard Mode')}
          </Button>
        )}
      </PageCenter>
    );
  }

  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

type UserWithFacilitatorPermissionsProviderProps = {
  children: ReactNode;
  facilitatorPermissions: FacilitatorPermissions;
};
export function UserWithFacilitatorPermissionsProvider({
  children,
  facilitatorPermissions,
}: UserWithFacilitatorPermissionsProviderProps) {
  const userContext = React.useContext(UserContext);
  const hydratedUserContext = React.useMemo(() => {
    return {
      ...userContext,
      facilitatorPermissions,
    };
  }, [userContext, facilitatorPermissions]);

  return (
    <UserContext.Provider value={hydratedUserContext}>
      {children}
    </UserContext.Provider>
  );
}

const useUser = (): UserContextType => {
  const context = React.useContext(UserContext);

  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider Context');
  }

  return context;
};

export { UserProvider, useUser, UserContext };
