import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { request } from 'utils/api';
import { useCanAccess } from 'hooks/useCanAccess';
import RoamingActivityItem, {
  RoamingActivityItemProps,
} from './RoamingActivityItem';
import styles from './RoamingActivity.module.css';
import { Icon, Label } from 'semantic';
import { FeatureFlag } from 'components';
import { formatDistance } from 'date-fns';
import { Form } from 'semantic-ui-react';
import { act } from 'react-dom/test-utils';

interface Entity {
  type: 'location' | 'token' | 'cdr' | 'session';
  id: string;
}

const entityActions = [
  {
    value: '',
    text: 'All',
  },
  {
    value: 'tokenAuthorize',
    text: 'Token authorization',
  },
  {
    value: 'broadcast',
    text: 'Change broadcast',
  },
  {
    value: 'cpoCommand',
    text: 'Remote commands',
  },
];

const fetchActivity = (
  actions: string[],
  entities: Entity[],
  onSuccess: (data: any[]) => void,
  onError: (message: string) => void
) => {
  if (!entities || entities.length === 0) {
    return;
  }
  request({
    method: 'POST',
    body: {
      actions: actions.filter(Boolean),
      limit: 50,
      entities,
      offset: 0,
    },
    path: `/1/roaming/activity`,
  })
    .then((response) => {
      if (response?.data) {
        onSuccess(response.data);
      }
    })
    .catch((error) => {
      onError(`Error fetching roaming activity: ${error}`);
    });
};

const requestBroadcast = (
  entities: Entity[],
  onSuccess: (data: any[]) => void,
  onError: (message: string) => void
) => {
  if (!entities || entities.length === 0) {
    return;
  }
  request({
    method: 'POST',
    body: {
      entities,
    },
    path: `/1/roaming/broadcast`,
  })
    .then((response) => {
      if (response?.data) {
        onSuccess(response.data);
      }
    })
    .catch((error) => {
      onError(`Error fetching roaming activity: ${error}`);
    });
};

interface Props {
  entities: Entity[];
  live?: boolean;
  text?: string;
}

const RoamingActivity: React.FC<Props> = ({ entities, ...props }) => {
  const [live, setLive] = useState(props.live || false);
  const [action, setAction] = useState<string>('');
  const [lastUpdated, setLastUpdated] = useState<Date>();
  const [activity, setActivity] = useState<RoamingActivityItemProps[]>([]);
  const [error, setError] = useState<string | undefined>(undefined);
  const { t } = useTranslation();

  const canAccessRoamingManagement = useCanAccess({
    endpoint: 'roamingManagement',
    // TODO(ggordan): This should be false, but there may be a bug in the canAccess method
    // need to come back to it
    allowAccountContext: true,
    requiredLevel: 'read',
  });

  useEffect(() => {
    if (!canAccessRoamingManagement) {
      return;
    }
    fetchActivity(
      [action],
      entities,
      (activity) => {
        setLastUpdated(new Date());
        setActivity(activity);
      },
      setError
    );
  }, [entities, canAccessRoamingManagement, action]);

  useEffect(() => {
    if (!canAccessRoamingManagement) {
      return;
    }

    const timeout = setInterval(() => {
      if (live) {
        fetchActivity(
          [action],
          entities,
          (activity) => {
            setLastUpdated(new Date());
            setActivity(activity);
          },
          setError
        );
      }
    }, 3000);
    return () => {
      clearInterval(timeout);
    };
  }, [canAccessRoamingManagement, live, action]);

  return (
    <div>
      <div className={styles.containerHeader}>
        <h3>
          {t('roamingActivity.title', 'Roaming activity')}
          {live && lastUpdated && (
            <span className={styles.liveNote}>
              (
              {t('roamingActivity.live', 'Last updated: {{date}}', {
                date: formatDistance(lastUpdated || new Date(), new Date()),
              })}
              )
            </span>
          )}
        </h3>
        <p className={styles.actionsBar}>
          <FeatureFlag feature="roaming_activity_request_broadcast">
            <Label
              className={styles.playPauseLabel}
              title={t(
                'roamingActivity.sync',
                'Trigger a sync of this entity with roaming partners'
              )}>
              <Icon
                size="tiny"
                color="white"
                name="sync"
                onClick={() => {
                  setLive(true);
                  requestBroadcast(
                    entities,
                    () => {},
                    () => {}
                  );
                }}
              />
            </Label>
          </FeatureFlag>
          <Label
            className={styles.playPauseLabel}
            title={t(
              'roamingActivity.realtime',
              'Refresh roaming activity for this entity every 3s'
            )}>
            {live ? (
              <Icon
                size="tiny"
                color="white"
                name="pause"
                onClick={() => setLive(false)}
              />
            ) : (
              <Icon
                size="tiny"
                color="white"
                name="play"
                onClick={() => setLive(true)}
              />
            )}
          </Label>
          <FeatureFlag feature="roaming_activity_request_broadcast">
            <Form.Select
              className={styles.dropdown}
              onChange={(e, value) => setAction((value?.value as string) || '')}
              options={entityActions}
            />
          </FeatureFlag>
        </p>
      </div>
      <div className={styles.container}>
        {error && <p>{error}</p>}
        {!error && (
          <>
            {activity.length === 0 ? (
              <p>
                {t(
                  'roamingActivity.nodata',
                  'No roaming activity found for the last 30 days.'
                )}
              </p>
            ) : (
              <div className={styles.activityList}>
                {activity.map((item, index) => (
                  <RoamingActivityItem key={index} item={item} />
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default RoamingActivity;
