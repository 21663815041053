import React, { useEffect } from 'react';
import { loadScript } from 'utils/script';
import { MAP_SDK_URL, API_URL } from 'utils/env';
import { useUser } from 'contexts/user';
import { useTranslation } from 'react-i18next';
import { getThemedField } from 'utils/theming';

const sdkRoot = new URL(MAP_SDK_URL).origin;

export default function Map() {
  const mapRef = React.useRef(null);
  const { i18n } = useTranslation();
  const { provider } = useUser();

  const brandColor = getThemedField(provider, 'primaryColorHex', false);
  const backgroundColor = getThemedField(
    provider,
    'secondaryBackgroundColorHex',
    false
  );

  async function boot() {
    await loadScript(`${MAP_SDK_URL}?v=${Date.now()}`);
    const RoadIO = (window as any).RoadIO;
    RoadIO.initRoadMap({
      domNode: mapRef.current,
      zoomLevel: 10,
      enableSidebar: true,
      enableZoomControls: true,
      provider: provider?.id,
      language: i18n.language,
      sdkRoot: sdkRoot,
      apiRoot: API_URL,
      gestureHandling: 'greedy',
      theming: {
        brandColor,
        backgroundColor,
        // cc @felix
        /*
        textColor: string;
        borderColor: string;
        fontFamily: string;
        */
      },
    });
  }

  useEffect(() => {
    if (mapRef.current) {
      boot();
    }
  }, [mapRef.current]);

  return (
    <div
      style={{
        width: '100%',
        height: 'calc(100vh - 130px)',
      }}
      ref={mapRef}>
      <div>Loading...</div>
    </div>
  );
}
