const {
  NODE_ENV,
  API_URL,
  SANDBOX_API_URL,
  APP_NAME,
  TECTONIC_URL,
  ENV_NAME,
  GOOGLE_KEY,
  API_KEY,
  I18N_URL,
  INTERCOM_APP_ID,
  MAP_WIDGET_BASE_URL,
  FLAGSMITH_CLIENT_KEY,
  FLAGSMITH_API_URL,
  OID_REDIRECT_BASE_API_URL,
  MIXPANEL_TOKEN,
  MAP_SDK_URL,
  HUBSPOT_PORTAL_ID,
  HUBSPOT_FORM_ID,
  ENABLE_HUBSPOT_FOR_PROVIDER,
  ROAD_PROVIDER_ID,
  CSP_NONCE,
} = window.__ENV__ || {};

const IS_STAGING = location.host === 'dashboard.e-flux.dev';
const IS_DEMO =
  location.host.match(/\.demo\.road\.dev$/) ||
  location.host.match(/\.demo\.public\.road\.dev$/);

export {
  CSP_NONCE,
  NODE_ENV,
  API_URL,
  SANDBOX_API_URL,
  APP_NAME,
  TECTONIC_URL,
  MIXPANEL_TOKEN,
  ENV_NAME,
  GOOGLE_KEY,
  API_KEY,
  OID_REDIRECT_BASE_API_URL,
  I18N_URL,
  INTERCOM_APP_ID,
  FLAGSMITH_CLIENT_KEY,
  FLAGSMITH_API_URL,
  MAP_WIDGET_BASE_URL,
  IS_STAGING,
  IS_DEMO,
  MAP_SDK_URL,
  HUBSPOT_PORTAL_ID,
  HUBSPOT_FORM_ID,
  ENABLE_HUBSPOT_FOR_PROVIDER,
  ROAD_PROVIDER_ID,
};
